<template>
<div v-if="errorText" class="error-container">
    <div class="form-error">{{ errorText }}</div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        errorText: {
            type: String,
            required: false
        }
    }
})
</script>

<style scoped lang="scss">
.error-container {
    position: relative;
    max-width: 100%;
    width: 100%;
    top: 14px !important;
    right: 0px !important;

    .form-error {
        border-radius: 2px;
        background: var(--red-300);
        color: #000;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        padding: 8px;

        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 50%;
        z-index: 3;
    }

    .form-error:after{
        content: "";
        position: absolute;

        border-top: 8px solid var(--red-300);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;

        margin-top: 20px;
        right: 10px;
    }
}
</style>
